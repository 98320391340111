@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-secondary-500 bg-neutral-50;
    font-weight: 400;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar-show::-webkit-scrollbar {
    display: block;
  }

  .scrollbar-show {
    -ms-overflow-style: auto;
    scrollbar-width: auto;
  }

  /* Hide Calendar Icon */
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

@layer utilities {
  .filter-neutral-00 {
    filter: invert(100%) sepia(13%) saturate(7449%) hue-rotate(203deg)
      brightness(117%) contrast(112%);
  }

  .filter-neutral-300 {
    filter: invert(77%) sepia(0%) saturate(7476%) hue-rotate(130deg)
      brightness(90%) contrast(79%);
  }

  .filter-neutral-400 {
    filter: invert(46%) sepia(16%) saturate(15%) hue-rotate(357deg)
      brightness(95%) contrast(90%);
  }

  .filter-neutral-500 {
    filter: invert(27%) sepia(0%) saturate(0%) hue-rotate(359deg)
      brightness(100%) contrast(102%);
  }

  .filter-brand-400 {
    filter: invert(61%) sepia(32%) saturate(5922%) hue-rotate(111deg)
      brightness(104%) contrast(98%);
  }

  .filter-strong_red {
    filter: brightness(0) saturate(100%) invert(24%) sepia(66%) saturate(2615%)
      hue-rotate(354deg) brightness(94%) contrast(90%);
  }

  .filter-honey_orange {
    filter: brightness(0) saturate(100%) invert(53%) sepia(41%) saturate(6775%)
      hue-rotate(21deg) brightness(86%) contrast(94%);
  }

  .filter-dark_yellow_pineapple {
    filter: brightness(0) saturate(100%) invert(56%) sepia(64%) saturate(4572%)
      hue-rotate(29deg) brightness(101%) contrast(93%);
  }

  .filter-lilac_purple {
    filter: brightness(0) saturate(100%) invert(73%) sepia(77%) saturate(5959%)
      hue-rotate(238deg) brightness(97%) contrast(80%);
  }

  .filter-interferer_magenta {
    filter: brightness(0) saturate(100%) invert(17%) sepia(89%) saturate(7462%)
      hue-rotate(319deg) brightness(90%) contrast(103%);
  }

  .filter-denim_blue {
    filter: brightness(0) saturate(100%) invert(30%) sepia(77%) saturate(570%)
      hue-rotate(162deg) brightness(101%) contrast(100%);
  }
}

.gm-style .gm-style-iw {
  font-family: inherit !important;
}

@layer utilities {

  /* Hide buttons/arrows into the inputs number on browser based on WebKit */
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    @apply appearance-none m-0;
  }

  /* Hide buttons/arrows into the inputs number on browser based on Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}